<template>
  <el-dialog
    title="提示"
    :visible.sync="dialogVisible"
    width="60%"
    :close-on-click-modal="false"
    @close="handleClose">
      <div class="title">
        <el-button plain icon="el-icon-upload2" type="primary" @click="showUploadFile">导入</el-button>
        <el-button plain icon="el-icon-download" type="primary" @click="downloadFile">下载模版</el-button>
      </div>
      <div class="table">
        <el-table
          :data="tableData"
          style="width: 100%">
          <el-table-column label="导入模版">
            <el-table-column
              prop="name"
              label="姓名">
            </el-table-column>
            <el-table-column
              prop="tel"
              label="手机号">
            </el-table-column>
            <el-table-column
              prop="dentificationNo"
              label="身份证号">
            </el-table-column>
            <el-table-column
              prop="productName"
              label="产品名称">
            </el-table-column>
            <el-table-column
              prop="productType"
              label="产品类型">
            </el-table-column>
            <el-table-column
              prop="teamName"
              label="团队名称">
            </el-table-column>
            <el-table-column
              prop="teamType"
              label="团队类型">
            </el-table-column>
            <el-table-column
              prop="price"
              label="收费金额">
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span>
    <uploadFile :url="url" @closeUpload="closeUpload" v-if="isShow"></uploadFile>
  </el-dialog>
</template>

<script>
import uploadFile from '@/views/customerManagement/component/uploadFile'
import {filedownaction} from 'src/utils/cities'
export default {
  data () {
    return {
      dialogVisible: true,
      isShow: false,
      url: '',
      tableData: []
    }
  },
  components: { uploadFile },
  methods: {
    handleClose(){
      this.$emit('closeImportOrder')
    },
    downloadFile(){
      window.location.href=filedownaction + "?type=orderManagement"
    },
    showUploadFile(){
      this.isShow = true
      this.url = '/orderComputer/parseOrderExcel'
    },
    closeUpload(val){
      console.log(val)
      this.isShow = false
      this.tableData = val
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  justify-content: flex-start;
  div {
    border: 1px solid #999;
    width: 130px;
    padding: 20px 0px;
    text-align: center;
  }
  :last-child {
    margin-left: 10px;
  }
}
.table {
  margin-top: 20px;
}
</style>