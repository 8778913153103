import { render, staticRenderFns } from "./separate.vue?vue&type=template&id=009c77b1&scoped=true&"
import script from "./separate.vue?vue&type=script&lang=js&"
export * from "./separate.vue?vue&type=script&lang=js&"
import style0 from "./separate.vue?vue&type=style&index=0&id=009c77b1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "009c77b1",
  null
  
)

export default component.exports