<template>
  <div class="organ-management-page">
    <el-row>
      <el-col :span="24">
        <el-card shadow="always">
          <el-form :inline="true" :model="form" class="demo-form-inline search" size="small">
            <el-form-item label="订单号:">
              <el-input v-model="form.area" :clearable="true" placeholder="请输入订单号"></el-input>
            </el-form-item>
            <el-form-item label="APP账户名:">
              <el-input v-model="form.phone" :clearable="true" placeholder="请输入APP账户名"></el-input>
            </el-form-item>
            <el-form-item label="APP手机号:">
              <el-input v-model="form.tel" :clearable="true" placeholder="请输入APP手机号"></el-input>
            </el-form-item>
            <el-form-item label="订单类型:">
              <el-select clearable v-model="form.region" @change="changeOrderType" placeholder="请选择订单类型">
                <el-option label="全部 " value=""></el-option>
                <el-option v-for="(item, index) in productList" :key="index" :label="item.dictionaryName" :value="item.dictionaryCode"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="缴费时间:">
              <el-date-picker clearable v-model="form.value1" value-format="yyyy-MM-dd" type="date" placeholder="请选择缴费时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="订单状态:">
              <el-select clearable v-model="form.type" placeholder="请选择订单状态">
                <el-option label="全部 " value=""></el-option>
                <el-option v-for="(item, index) in typeList" :key="index" :label="item.name" :value="item.code"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button icon="el-icon-search" type="primary" :disabled="disabledSearch" @click="handleSearch">查询</el-button>
              <el-button plain icon="el-icon-upload2" type="primary" @click="importOrder">导入</el-button>
              <el-button plain icon="el-icon-download" type="primary" @click="downloadFile">导出</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <el-card class="card" shadow="always">
      <div>
        <el-button plain icon="el-icon-plus" type="primary" @click="addNew('', 1)">添加</el-button>
        <el-button plain icon="el-icon-delete" type="danger" @click="handleDelete">删除</el-button>
      </div>
      <div class="table-block">
        <div class="Selectnum">已选中 <span style="color:#5479FF">{{multipleSelection.length}}</span> 条信息</div>
        <el-table :data="tableData" @row-click="handleClickTableRow" :header-cell-style="{'text-align': 'center','background': '#EEF1FC', 'color': '#999'}" :cell-style="{'text-align':'center'}" ref="multipleTable"
          style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection" fixed width="50"></el-table-column>
          <el-table-column prop="orderNo" label="订单号" width="180"> </el-table-column>
          <el-table-column prop="userName" label="APP账户名"> </el-table-column>
          <el-table-column prop="telTuoM" label="APP手机号"> </el-table-column>
          <el-table-column prop="catalogName" label="订单类型"> </el-table-column>
          <el-table-column prop="orderTitle" label="产品名称"> </el-table-column>
          <el-table-column prop="state" label="订单状态"> </el-table-column>
          <el-table-column prop="price1" label="订单金额"> </el-table-column>
          <el-table-column prop="laiyuan" label="来源"> </el-table-column>
          <el-table-column prop="payTime" width="180" label="缴费时间"> </el-table-column>
          <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <el-button @click="toggle(scope.row, true)" v-if="scope.row.status == 2 || scope.row.status == 1" class="updatebutton" type="text" size="small">修改</el-button>
              <el-button @click="handleLook(scope.row, false)" type="text" size="small">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total" background>
        </el-pagination>
      </div>
    </el-card>
    <addNewOrder @closeAddOrder="closeAddOrder" v-if="showAddOrder"></addNewOrder>
    <importOrder @closeImportOrder="closeImportOrder" v-if="showImportOrder"></importOrder>
    <orderOperation :info="info" :isChange="isChange" @closeOrderOperation="closeOrderOperation" v-if="showOrderOperation"></orderOperation>
    <separate :info="info" v-if="showSeparate" @closeSeparate="closeSeparate"></separate>
  </div>
</template>

<script>
import addNewOrder from '@/views/orderPurse/components/addNewOrder'
import orderOperation from '@/views/orderPurse/components/orderOperation'
import importOrder from '@/views/orderPurse/components/importOrder'
import separate from '@/views/orderPurse/components/separate'
import { postRequest } from 'src/api/index'
import { fileaction } from 'src/utils/cities'
export default {
  data() {
    return {
      form: {
        area: '',
        type: '',
        teamName: '',
        name: '',
        phone: '',
        regison: '',
        value1: '',
        value2: '',
      },
      typeList: [
        {
          name: '待支付',
          code: 1,
        },
        {
          name: '部分支付',
          code: 2,
        },
        {
          name: '全部支付',
          code: 3,
        },
        {
          name: '已取消',
          code: -1,
        },
        {
          name: '已删除',
          code: -2,
        },
      ],
      tableData: [],
      multipleSelection: [],
      showAddOrder: false,
      showImportOrder: false,
      showOrderOperation: false,
      productList: '',
      isChange: '',
      info: '',
      currentPage: 1,
      pageSize: 10,
      total: null,
      showSeparate: false,
      disabledSearch: false,
      loading: true,
    }
  },
  components: { addNewOrder, importOrder, orderOperation, separate },
  mounted() {
    let data = this.$route.query.data
    if (data) {
      console.log(data)
    }
    // console.log(123123123,process.env.VUE_APP_APP_URL)
    // 会籍、单品
    postRequest('/dict/getDictByCategory/', { dictionaryCategoryCode: '04' }).then(res => {
      console.log(res, '会籍、单品')
      this.productList = res
    })
    // 查询所有订单
    this.getAllOrderInfoByPage()
    // this.getAllOrderInfo()
  },
  methods: {
    // 点击行选中
    handleClickTableRow(row) {
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    // 选中项
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
    },
    changeOrderType(val) {
      console.log(val)
    },
    downloadFile() {
      if (this.form.region == undefined) {
        this.form.region = ''
      }
      if (this.form.tel == undefined) {
        this.form.tel = ''
      }
      window.open(
        fileaction +
          `/orderComputer/exportOrderExcel?orderNo=${this.form.area}&appUserName=${this.form.phone}&orderDate=${
            this.form.value1
          }&orderType=${this.form.region}&tel=${this.form.tel}&stuts=${this.form.type}&computerToken=${
            JSON.parse(localStorage.getItem('newToken')).computerToken
          }`,
      )
    },
    addNew() {
      console.log(123)
      this.showAddOrder = true
    },
    closeSeparate() {
      this.showSeparate = false
      this.getAllOrderInfoByPage()
    },
    closeAddOrder() {
      this.showAddOrder = false
      this.getAllOrderInfoByPage()
    },
    toggle(val, isChange) {
      this.info = val
      this.isChange = isChange
      console.log(123,val)
      if (val.catalogCode == 'DP' ) {
        this.showSeparate = true
      } else if (val.catalogCode == 'HJ' || val.catalogCode == 'TJ') {
        this.showOrderOperation = true
      }
    },
    handleLook(val, isChange) {
      this.info = val
      this.isChange = isChange
      this.showOrderOperation = true
    },
    closeImportOrder() {
      this.showImportOrder = false
    },
    importOrder() {
      this.showImportOrder = true
    },
    closeOrderOperation() {
      this.showOrderOperation = false
      this.getAllOrderInfoByPage()
    },
    getAllOrderInfoByPage(no) {
      let data = {
        appUserName: this.form.phone ? this.form.phone : '',
        orderNo: this.form.area ? this.form.area : '',
        orderDate: this.form.value1 ? this.form.value1 : '',
        orderType: this.form.region ? this.form.region : '',
        pageNo: no ? no : this.currentPage ? this.currentPage : '',
        pageSize: this.pageSize ? this.pageSize : '',
        tel: this.form.tel ? this.form.tel : '',
        stuts: this.form.type ? this.form.type : '',
      }
      postRequest('/orderComputer/queryOrderInfoByPge', data).then(res => {
        console.log(res, '分页查询结果')
        this.total = res.count

        res.data.map(item => {
          if (item.status == '1') {
            item.state = '待支付'
          } else if (item.status == '2') {
            item.state = '部分支付'
          } else if (item.status == '3') {
            item.state = '已完成支付'
          } else if (item.status == '-1') {
            item.state = '取消支付'
          } else if (item.status == '-2') {
            item.state = '删除订单'
          }
          if (item.catalogCode == 'DP') {
            item.catalogName = '单品'
          } else if (item.catalogCode == 'HJ') {
            item.catalogName = '会籍'
          } else if (item.catalogCode == 'TJ') {
            item.catalogName = '体检'
          }
          if (item.referType == '1') {
            item.laiyuan = 'APP端'
          } else if (item.referType == '2') {
            item.laiyuan = '管理端'
          }
          if (item.price) {
            let reg = /(\d{1,3})(?=(\d{3})+(\.\d*)?$)/g
            item.price1 = item.price
            item.price1 = '¥ ' + item.price1.toString().replace(reg, '$&,')
          }
        })
        this.tableData = res.data
        this.tableData.forEach(item=>{
          if (item.upgradeFlag == 1) {
            item.orderTitle=item.orderTitle+' 【升级】'
          }
        })
      })
    },
    handleDelete() {
      if (this.multipleSelection.length <= 0) {
        this.$message({
          message: '请选择删除项',
          type: 'warning',
        })
      } else {
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true,
        })
          .then(() => {
            let idList = []
            this.multipleSelection.map(item => {
              idList.push(item.id)
            })
            let data = {
              idList,
            }
            postRequest('/orderComputer/delete', data).then(res => {
              console.log(res)
              this.$message({
                message: '删除成功',
                type: 'success',
              })
              this.getAllOrderInfoByPage()
            })
          })
          .catch(() => {
            this.$message({
              type: 'warning',
              message: '已取消删除',
            })
          })
      }
    },
    handleSearch() {
      this.currentPage = 1
      this.getAllOrderInfoByPage()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.pageSize = val
      this.getAllOrderInfoByPage()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.currentPage = val
      this.getAllOrderInfoByPage()
    },
  },
}
</script>

<style lang="scss" scoped>
// .search > .el-form-item {
//   margin-right: 2px !important;
// }
.organ-management-page {
  background: transparent;
}
.Selectnum {
  padding: 6px 30px;
  border: 1px solid #bfcbf4;
  font-size: 12px;
  color: #666;
  border-radius: 5px;
  margin-bottom: 10px;
}
</style>
