<template>
  <el-dialog :visible.sync="dialogVisible" width="75%" :close-on-click-modal="false" @close="handleClose">
    <div class="inner" v-loading="loading">
      <el-card class="line-1">
        <el-row>
          <el-col>
            <p>订单号: <span>{{result.orderNo}}</span></p>
          </el-col>
          <el-col v-if="result.status > 0" class="step-all">
            <div class="step-1">
              <span class="el-icon-more"></span>
              <span>等待支付</span>
            </div>
            <div class="middleBox" v-if="result.status !=1"></div>
            <div v-if="result.status !=1" class="step-2">
              <i class="el-icon-check" v-if="result.status == '3' || result.status == '2'"></i>
              <span>{{result.state}}</span>
            </div>
          </el-col>
          <el-col v-if="result.status < 0" class="step-all-2">
            <div class="step-1">
              <span class="el-icon-more"></span>
              <span>等待支付</span>
            </div>
            <div class="middleBox" v-if="result.status !=1"></div>
            <div v-if="result.status !=1" class="step-2">
              <i class="el-icon-close" v-if="result.status == '-1' || result.status == '-2'"></i>
              <span>{{result.state}}</span>
            </div>
          </el-col>
        </el-row>
      </el-card>
      <el-card class="line-2">
        <p>订单信息</p>
        <div v-if="result.catalogCode == 'HJ' || result.catalogCode == 'TJ'">
          <el-row :gutter="20">
            <el-col :span="4">APP账户名:&nbsp;&nbsp;{{result.appUserName}}</el-col>
            <el-col :span="6">APP手机号:&nbsp;&nbsp;{{result.tel}}</el-col>
            <el-col :span="6">订单总额:&nbsp;&nbsp;<span class="totalMoney">¥{{result.totalPrice}}</span></el-col>
            <el-col :span="8">支付时间:&nbsp;&nbsp;{{result.createTime}}</el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="4">团体类别:&nbsp;&nbsp;{{result.nameOfTeam}}</el-col>
            <el-col :span="6">产品类别:&nbsp;&nbsp;{{result.catalogName}}</el-col>
          </el-row>
        </div>
        <div v-if="result.catalogCode == 'DP'">
          <el-row :gutter="20">
            <el-col :span="6">APP账户名:&nbsp;&nbsp;{{result.appUserName}}</el-col>
            <el-col :span="5">APP手机号:&nbsp;&nbsp;{{result.tel}}</el-col>
            <el-col :span="5">订单总额:&nbsp;&nbsp;<span class="totalMoney">¥{{result.totalPriceDP}}</span></el-col>
            <el-col :span="8">创建时间:&nbsp;&nbsp;{{result.createTime}}</el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">产品:&nbsp;&nbsp;{{result.catalogName}}</el-col>
            <el-col :span="5">权益享有人:&nbsp;&nbsp;{{result.orderRightsOwners == null ? '' : result.orderRightsOwners[0].name}}</el-col>
            <el-col :span="5">享有人手机号:&nbsp;&nbsp;{{result.orderRightsOwners == null ? '' : result.orderRightsOwners[0].trl}}</el-col>
            <el-col :span="8">享有人证件号:&nbsp;&nbsp;{{result.orderRightsOwners == null ? '' : result.orderRightsOwners[0].identificationNo}}</el-col>
          </el-row>
        </div>
      </el-card>
      <el-card class="line-3">
        <p>产品购买详情</p>
        <el-table :header-cell-style="{'text-align': 'center','background': '#EEF1FC', 'color': '#666', 'font-size': '14px', 'font-weight': '400'}" :cell-style="{'text-align':'center'}" :data="tableData"
          style="width: 100%">
          <el-table-column prop="productName" label="产品">
          </el-table-column>
          <el-table-column prop="name" label="权益享有人">
          </el-table-column>
          <el-table-column prop="trl" label="电话">
          </el-table-column>
          <el-table-column prop="identificationNo" label="身份证号">
          </el-table-column>
          <el-table-column prop="receivedprice" label="实付金额">
          </el-table-column>
        </el-table>
      </el-card>
    </div>
    <el-card class="line-4" v-if="isChange">
      <el-form ref="form" :model="form" label-width="100px" :disabled='result.canPayFlag'>
        <el-form-item label="剩余应收金额:">
          {{result.payPrice}}
        </el-form-item>
        {{ changeState || result.status == '3' }}
        <el-form-item label="收款时间">
          <el-date-picker value-format="yyyy-MM-dd" type="date" placeholder="选择日期" v-model="form.date1" style="width: 100%;"></el-date-picker>
        </el-form-item>
        <el-form-item label="收款金额">
          <el-input :disabled="changeState || result.status == '3'" type="number" placeholder="请输入收款金额" @input="numberChange(arguments[0],result.payPrice)" @change="numberChange(arguments[0],result.payPrice)"
            v-model="form.money"></el-input>
        </el-form-item>
      </el-form>
    </el-card>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="sureCommit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { postRequest } from '@/api'
export default {
  data() {
    return {
      dialogVisible: true,
      isShow1: '',
      changeState: true,
      loading: true,
      isShow2: '',
      result: '',
      tableData: [],
      form: {
        name: '',
        discount: '',
        date1: '',
        money: '',
      },
    }
  },
  props: ['info', 'isChange'],
  mounted() {
    if (this.info) {
      console.log(this.info, 'info')
      let data = {
        idList: [this.info.id],
      }
      postRequest('/orderComputer/queryOrderDetailsInfo', data)
        .then(res => {
          console.log(res, '查询')
          if (res.orderRightsOwners && res.orderRightsOwners.length > 0) {
            this.changeState = false
          }
          // let reg = /1(\d{2})\d{4}(\d{4})/g;
          // if (res.orderRightsOwners) {
          //   res.orderRightsOwners.map(item => {
          //     item.phone = item.trl.replace(reg,"1$1****$2");
          //   })
          // }
          if (res.status == '1') {
            res.state = '待支付'
          } else if (res.status == '2') {
            res.state = '部分支付'
          } else if (res.status == '3') {
            res.state = '已全部支付'
          } else if (res.status == '-1') {
            res.state = '已取消'
          } else if (res.status == '-2') {
            res.state = '已删除'
          }
          if (res.teamType == 'JT') {
            res.nameOfTeam = '家庭'
          } else if (res.teamType == 'JY') {
            res.nameOfTeam = '精英'
          } else if (res.teamType == 'GR') {
            res.nameOfTeam = '个人'
          } else if (res.teamType == 'SVIP') {
            res.nameOfTeam = 'SVIP'
          }
          if (res.catalogCode == 'DP') {
            res.catalogName = '单品'
          } else if (res.catalogCode == 'HJ') {
            res.catalogName = '会籍'
          } else if (res.catalogCode == 'TJ') {
            res.catalogName = '体检'
          }
          this.result = res
          this.tableData = this.result.orderRightsOwners
          console.log(this.tableData,res)
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    }
  },
  methods: {
    handleClose() {
      this.$emit('closeOrderOperation')
    },
    clickRow(val) {
      console.log(val)
    },
    numberChange(val, maxNum) {
      //转换数字类型
      this.form.money = Number(val)
      //重新渲染
      this.$nextTick(() => {
        //比较输入的值和最大值，返回小的
        let num = Math.min(Number(val), maxNum)
        //输入负值的情况下， = 0（可根据实际需求更该）
        if (num <= 0) {
          this.form.money = 0
        } else {
          //反之
          this.form.money = num
        }
      })
    },
    calculate() {
      let price = this.form.discountPrice / this.result.totalPrice
      this.form.discount = price.toFixed(2)
      console.log(this.form.discount)
    },
    sureCommit() {
      // 修改
      console.log(this.form.date1, this.form.money)
      if (this.isChange) {
        if (this.form.date1 == '' || this.form.money == '') {
          this.$message({
            message: '请完善收款时间及金额',
            type: 'warning',
          })
        } else {
          console.log('修改')
          if (this.info.catalogCode == 'HJ' || this.info.upgradeFlag == 1 || this.info.catalogCode == 'TJ') {
            let data = {
              orderId: this.result.id,
              tradeTime: this.form.date1,
              payAmount: this.form.money,
              payStatus: '3',
            }
            postRequest('/orderComputer/confirmPay', data).then(res => {
              console.log(res)
              this.$message({
                message: '修改成功',
                type: 'success',
              })
              this.handleClose()
            })
          }
          if (this.info.catalogCode == 'DP' && !this.info.upgradeFlag) {
            let data = {
              orderId: this.result.id,
              tradeTime: this.form.date1,
              payAmount: this.form.discountPrice,
              payStatus: '3',
            }
            postRequest('/orderComputer/confirmPay', data).then(res => {
              console.log(res)
              this.$message({
                message: '修改成功',
                type: 'success',
              })
              this.handleClose()
            })
          }
        }
      } else {
        console.log('查看')
        this.handleClose()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.line-1 {
  background: #fff;
  p {
    span {
      color: #5479ff;
    }
  }
}
.line-2 {
  margin-top: 25px;
  background: #fff;
  .el-row {
    margin-bottom: 17px;
    padding-left: 10px;
    .el-col {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #676767;
    }
  }
  .button-1 {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
  }
}
.line-3 {
  margin-top: 25px;
  background: #fff;
  .el-table {
    font-size: 14px;
    color: #676767;
    font-weight: 400;
    font-family: PingFang SC;
  }
}
.line-4 {
  margin-top: 25px;
  background: #fff;
  .el-form-item,
  .el-form-item__label {
    font-size: 14px !important;
    color: #676767 !important;
    font-weight: 400;
    font-family: PingFang SC;
  }
}
p {
  font-size: 16px;
  margin-bottom: 19px;
  margin-top: 0;
  font-weight: 400;
  color: #444444;
  font-family: PingFang SC;
}
.el-form {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.el-dialog {
  background: #f7f8fa;
}
// .inner {
//   background: #fff;
//   >.el-row {
//     margin-top: 20px;
//   }
// }
.detail {
  margin: 20px 0 0 10px;
}
.DP {
  margin-top: 20px;
}
.el-dialog__footer {
  text-align: center !important;
}
.el-step {
  color: #5967ff;
}
.el-step__title .is-process {
  color: #5967ff !important;
}
.el-step__icon .is-text {
  background: #5967ff !important;
  color: #fff;
}
.step-all {
  display: flex;
  align-items: center;
  .step-1 {
    :first-child {
      display: inline-block;
      width: 24px;
      height: 24px;
      background: #5067ff;
      text-align: center;
      line-height: 24px;
      border-radius: 50%;
      color: #fff;
    }
    :last-child {
      margin-left: 7px;
      font-size: 12px;
      color: #5479ff;
    }
  }
  .middleBox {
    width: 100px;
    border-top: 1px solid #5479ff;
    margin: 0 5px;
  }
  .step-2 {
    display: flex;
    :first-child {
      font-size: 18px;
      background: #5067ff;
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      color: #fff;
      text-align: center;
      line-height: 24px;
    }
    :last-child {
      margin-left: 5px;
      display: inline-block;
      height: 28px;
      line-height: 28px;
      font-size: 12px;
      color: #5067ff;
    }
  }
}
.step-all-2 {
  display: flex;
  align-items: center;
  .step-1 {
    :first-child {
      display: inline-block;
      width: 24px;
      height: 24px;
      background: #cccccc;
      text-align: center;
      line-height: 24px;
      border-radius: 50%;
      color: #fff;
    }
    :last-child {
      margin-left: 7px;
      font-size: 12px;
      color: #cccccc;
    }
  }
  .middleBox {
    width: 100px;
    border-top: 1px solid #cccccc;
    margin: 0 5px;
  }
  .step-2 {
    display: flex;
    :first-child {
      font-size: 18px;
      background: #cccccc;
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      color: #fff;
      text-align: center;
      line-height: 24px;
    }
    :last-child {
      margin-left: 5px;
      display: inline-block;
      height: 28px;
      line-height: 28px;
      font-size: 12px;
      color: #cccccc;
    }
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='“number”'] {
  -moz-appearance: textfield;
}
.title {
  background: #eef1fc;
  color: #666666;
  font-size: 14px;
  padding: 17px 10px;
}
.totalMoney {
  color: #e95a52;
}
.bottom-line {
  width: 100%;
  height: 1px;
  background: #edf0f6;
}
</style>