<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="60%"
    :close-on-click-modal="false"
    @close="handleClose">
    <div>
      <el-card class="card-top">
        <el-form ref="form" :model="form">
          <el-row class="row">
            <el-col :span="20" class="col">
              <el-form-item prop="state">
                <el-autocomplete
                  v-model="form.state"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入客户电话"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
            </el-col>
          </el-row>
          </el-form>
        <el-form ref="form" :rules="rules" :model="form" label-width="90px">
          <div class="customerInfo">
            <el-form-item label="客户姓名:">
              <el-input @input="inputMoney" :disabled="haveName" placeholder="请输入客户姓名" v-model="customerName"></el-input>
            </el-form-item>
            <el-form-item label="客户电话:">
              <span>{{choose.tel}}</span>
            </el-form-item>
          </div>
          <div style="display: flex; justify-content: space-around">
            <el-form-item label="身份证号:">
              <el-input @input="inputMoney" @blur="inputIDCard" :disabled="haveID" placeholder="请输入身份证号" v-model="customerId"></el-input>
            </el-form-item>
            <el-form-item label="会员号:" style="text-align: left;">
              <span>{{choose.vipCode}}</span>
            </el-form-item>
          </div>
        </el-form>
      </el-card>
      <el-card>
        <el-form ref="form" :rules="rules" :model="form" label-width="80px">
          <el-row>
            <el-col :span="12">
              <el-form-item label="产品类型" prop="type">
                <el-select @change="handlecChange" v-model="form.type" placeholder="请选择产品类型">
                  <el-option v-for="(item, index) in effectiveList" :key="index" :label="item.productName" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="销售价格">
                <el-input @input="inputMoney" placeholder="请输入销售价格" v-model="form.money"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="缴费时间" prop="date1">
                <el-date-picker
                  v-model="form.date1"
                  type="datetime"
                  @change="changeTime"
                  placeholder="请选择缴费时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="收款金额" prop="address">
                <el-input placeholder="请输入收款金额" @input="numberChange(arguments[0],form.money)" @change="numberChange(arguments[0],form.money)" type="number" v-model="form.address"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="sureCommit('form')">确 定</el-button>
    </span>
    <addUsers v-if="showAddUser" @closeAdd="closeAdd" :info="info" :title="title"></addUsers>
  </el-dialog>
</template>

<script>
import addUsers from '@/views/customerManagement/component/addUsers'
import { postRequest } from '@/api'
import { formatTime } from '@/utils/utils'
export default {
  data () {
    return {
      form: {
        state: ''
      },
      form: {
        address:'',
        type: '',
        money: '',
        date1: '',
      },
      rules:{
        state: [
          {required: true, message: '请输入客户姓名', trigger: 'change'}
        ],
        type:[
          {required: true, message: '请选择产品类型', trigger: 'change'}
        ],
        date1:[
          {required: true, message: '请选择时间', trigger: 'change'}
        ],
        address:[
          {required: true, message: '请输入收款金额', trigger: 'blur'}
        ],
      },
      dialogVisible: true,
      showAddUser: false,
      info: '',
      productName: '',
      title: '',
      choose: '',
      effectiveList: [],
      customerList: [],
      customerName: '',
      customerId: '',
      haveName: true,
      haveID: true
    }
  },
  components: { addUsers },
  mounted(){
    postRequest('/product/queryProduct/', {}).then(res=>{
      console.log(res, '产品列表')
      let arr = []
      res.map(item => {
        if (item.status == '1') {
          arr.push(item)
        }
      })
      this.effectiveList = arr
    }),
    postRequest('/customer/queryCustomAndAppUser',{}).then(res=>{
      console.log(res,'客户list')
      this.customerList = res
    })
  },
  methods: {
    handleClose(){
      this.$emit('closeAddOrder')
    },
    closeAdd() {
      this.showAddUser = false
    },
    numberChange (val,maxNum) {
     //转换数字类型
        this.form.address = Number(val)
        //重新渲染
        this.$nextTick(()=>{
            //比较输入的值和最大值，返回小的
            let num = Math.min(Number(val),maxNum)
            //输入负值的情况下， = 0（可根据实际需求更该）
            if(num<0) {
                this.form.address = 0
            }else {
                //反之
                this.form.address = num
            }
        })
    },
    addUser () {
      this.showAddUser = true
      this.info = ''
      this.title = '新增客户'
    },
    handleSelect(item) {
      console.log(item);
      this.choose = item
      if (item.name) {
        this.customerName = item.name
        this.haveName = true
      } else {
        this.haveName = false
        this.customerName = ''
      }
      if (item.identificationNo) {
        this.haveID = true
        this.customerId = item.identificationNo
      } else {
        this.haveID = false
        this.customerId = ''
      }
    },
    handlecChange(val){
      console.log(val)
      let data = {
        productId: val,
        userId: this.choose.userId,
        customId: this.choose.customId
      }
      postRequest('/product/queryCustomProductPrice1', data).then(res=>{
        console.log(res, 'jieguo')
        this.form.money = res.productPrice
        this.form.integral = res.integral
        this.form.catalogCode = res.catalogCode
        this.form.teamType = res.teamType
        this.form.orderTitle = res.productName
      })
    },
    inputMoney(){
      this.$forceUpdate()
    },
    inputIDCard(){
      console.log(this.customerId)
      var reg = /(^[1-9]\d{5}(((1[89]|20)\d{2}(((0[13578]|1[0-2])(0[1-9]|[12][0-9]|3[01]))|((0[469]|11)(0[1-9]|[12][0-9]|30))|(02(0[1-9]|[1][0-9]|2[0-8]))))|((((1[89]|20)(0[48]|[2468][048]|[13579][26]))|((19|20)00))0229))\d{3}(\d|X|x)$)/;
      console.log(reg.test(this.customerId))
      if (!reg.test(this.customerId)) {
        this.$message({
          message: '请输入正确的身份证号',
          type: 'warning'
        })
      }
    },
    changeTime(val){
      console.log(val)
      console.log(this.form.date1)
      this.form.date1 = this.form.date1.getFullYear() + '-' + (this.form.date1.getMonth() + 1) + '-' + this.form.date1.getDate() + ' ' + this.form.date1.getHours() + ':' + this.form.date1.getMinutes() + ':' + this.form.date1.getSeconds()
    },
    querySearchAsync(queryString, cb) {
      this.form.type = ''
      this.form.money = ''
      this.form.date1 = ''
      this.form.address = ''
      console.log(queryString)
      console.log('远程查询')
      postRequest(`/customer/queryCustomAndAppUser?customNameOrTel=${queryString}`).then(res=>{
        console.log(res)
        for(let i = 0; i< res.length; i++) {
          res[i].value ='客户姓名:  ' + res[i].name + ' ( 客户电话: ' + res[i].tel + ')'
        }
        cb(res)
      })
    },
    sureCommit(form1){
      this.$refs[form1].validate((valid) => {
          if (valid) {
            let data = {
              customId: this.choose.customId,
              appUserId: this.choose.userId,
              productId: this.form.type,
              appUserName: this.customerName,
              identificationNo: this.customerId,
              tel: this.choose.tel,
              totalPrice: this.form.money,   
              tradeTime: this.form.date1,
              payPrice: this.form.address,
              integral:this.form.integral,
              catalogCode:this.form.catalogCode,
              teamType: this.form.teamType,
              orderTitle: this.form.orderTitle,
              tel:this.choose.tel,
              productItems: [
                {
                  productId: this.form.type,
                  productName: this.productName
                }
              ]
            }
            postRequest('/orderComputer/addSaveOrderInfo', data).then(res=> {
              console.log(res)
              this.$message({
                message: '添加成功',
                type: 'success'
              })
              this.handleClose()
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  text-align: center;
  margin-bottom: 20px;
}
.el-form-item__content {
  margin-left: 0 !important;
}
.el-select {
  width: 100% !important;
}
.el-form > .el-row {
  margin: 20px 0;
}
.row {
  display: flex;
  justify-content: center;
  .col {
    width: 50%;
  }
}
.el-autocomplete {
  width: 100%;
}
.el-date-editor {
  width: 100%;
}
.customerInfo {
  display: flex;
  justify-content: space-around;
}
.card-top {
  margin-bottom: 20px;
  span {
    display: inline-block;
    width: 250px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 0 15px;
    height: 32px;
    background: #f5f7fa;
  }
}
</style>
