<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="75%"
    @close="handleClose">
    <div class="inner" v-loading="loading">
      <el-card class="line-1">
        <el-row>
          <el-col><p>订单号:&nbsp;&nbsp;<span class="p-span">{{result.orderNo}}</span></p></el-col>
          <el-col class="step-all">
            <div class="step-1">
              <span class="el-icon-more"></span>
              <span>等待支付</span>
            </div>
            <div class="middleBox" v-if="result.status !=1"></div>
            <div v-if="result.status !=1" class="step-2">
              <i class="el-icon-check" v-if="result.status == '3' || result.status == '2'"></i>
              <i class="el-icon-close" v-if="result.status == '-1' || result.status == '-2'"></i>
              <span>{{result.state}}</span>
            </div>
          </el-col>
          <el-col v-if="result.status < 0" class="step-all-2">
            <div class="step-1">
              <span class="el-icon-more"></span>
              <span>等待支付</span>
            </div>
            <div class="middleBox" v-if="result.status !=1"></div>
            <div v-if="result.status !=1" class="step-2">
              <i class="el-icon-close" v-if="result.status == '-1' || result.status == '-2'"></i>
              <span>{{result.state}}</span>
            </div>
          </el-col>
        </el-row>
      </el-card>
      <el-card class="line-2">
        <p>订单信息</p>
        <el-row :gutter="20">
          <el-col :span="5">APP账户名:&nbsp;&nbsp;{{result.appUserName}}</el-col>
          <el-col :span="6">APP手机号:&nbsp;&nbsp;{{result.tel}}</el-col>
          <el-col :span="5">订单总额:&nbsp;&nbsp;<span class="totalMoney">¥{{result.totalPriceDP}}</span></el-col>
          <el-col :span="8">创建时间:&nbsp;&nbsp;{{result.createTime}}</el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="5">产品:&nbsp;&nbsp;{{result.catalogName}}</el-col>
          <el-col :span="6">权益享有人:&nbsp;&nbsp;{{this.result.orderRightsOwners == null ? '' : this.result.orderRightsOwners[0].name}}</el-col>
          <el-col :span="5">享有人手机号:&nbsp;&nbsp;{{this.result.orderRightsOwners == null ? '' : this.result.orderRightsOwners[0].trl}}</el-col>
          <el-col :span="8">享有人身份证号:&nbsp;&nbsp;{{this.result.orderRightsOwners == null ? '' : this.result.orderRightsOwners[0].identificationNo}}</el-col>
        </el-row>
        <div class="button-1">
          <el-button type="primary" plain @click="changeShowButton" v-show="!discount" class="button-1">修改折扣</el-button>
          <el-button type="danger" plain v-show="discount" @click="changeShowButton" class="button-1">删除折扣</el-button>
        </div>
      </el-card>
      <el-card v-show="discount" class="line-3">
        <p>修改折扣</p>
        <el-row>
          <el-form ref="form" :inline="true" :model="form" label-width="80px">
            <el-form-item label="折后价">
              <el-input @blur="calculate" placeholder="请输入折后价" @input="numberChange(arguments[0],result.totalPriceDP)" @change="numberChange(arguments[0],result.totalPriceDP)" :disabled="isChange" v-model="form.discountPrice"></el-input>
            </el-form-item>
            <el-form-item label="折扣">
              <el-input disabled placeholder="请输入折扣" v-model="form.discount"></el-input>
            </el-form-item>
          </el-form>
        </el-row>
      </el-card>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="sureCommit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { postRequest } from '@/api'
import { formatTime } from '@/utils/utils'
export default {
  data () {
    return {
      dialogVisible: true,
      loading: true,
      result: {},
      isChange: true,
      discount: false,
      active: 1,
      form: {
        discount: '',
        discountPrice: ''
      }
    }
  },
  props: ['info'],
  mounted () {
    if (this.info) {
      console.log(this.info, 'info')
      let data = {
        idList: [
          this.info.id
        ]
      }
      postRequest('/orderComputer/queryOrderDetailsInfo', data).then(res=>{
        console.log(res, '查询')
        this.loading = false
        this.form.discountPrice = res.discountOverPrice
        let price = (this.form.discountPrice / res.totalPriceDP).toFixed(2)
        this.form.discount = price
        if (res.orderRightsOwners && res.orderRightsOwners.length > 0) {
          this.changeState = false
        }
        // let reg = /1(\d{2})\d{4}(\d{4})/g;
        // if (res.orderRightsOwners) {
        //   res.orderRightsOwners.map(item => {
        //     item.phone = item.trl.replace(reg,"1$1****$2");
        //   })
        // }
        if (res.status == '1') {
          res.state = '待支付'
        } else if (res.status == '2') {
          res.state = '部分支付'
        } else if (res.status == '3') {
          res.state = '已全部支付'
        } else if (res.status == '-1') {
          res.state = '已取消'
        } else if (res.status == '-2') {
          res.state = '已删除'
        } 
        if (res.teamType == 'JT') {
          res.nameOfTeam = '家庭'
        } else if (res.teamType == 'JY') {
          res.nameOfTeam = '精英'
        } else if (res.teamType == 'GR') {
          res.nameOfTeam = '个人'
        } else if (res.teamType == 'SVIP') {
          res.nameOfTeam = 'SVIP'
        }
        if (res.catalogCode == 'DP') {
          res.catalogName = '单品'
        } else if (res.catalogCode == 'HJ') {
          res.catalogName = '会籍'
        }
        this.result = res

        if (this.result.status ==1) {
          this.active = 1
        } else {
          this.active = 2
        }
        if (this.result.status == 1 || this.result.status == 2) {
          this.isChange = false
        }
      }).catch(()=>{
        this.loading = false
      })
    }
  },
  methods: {
    handleClose(){
      this.$emit('closeSeparate')
    },
    changeShowButton(){
      this.discount = !this.discount
    },
    calculate(){
      console.log(this.form.discountPrice, '1', this.result.totalPrice, '2')
      let price = this.form.discountPrice / this.result.totalPriceDP
      console.log(price)
      this.form.discount = price.toFixed(2)
      
    },
    numberChange (val,maxNum) {
     //转换数字类型
        this.form.discountPrice = Number(val)
        //重新渲染
        this.$nextTick(()=>{
            //比较输入的值和最大值，返回小的
            let num = Math.min(Number(val),maxNum)
            //输入负值的情况下， = 0（可根据实际需求更该）
            if(num<0) {
                this.form.discountPrice = 0
            }else {
                //反之
                this.form.discountPrice = num
            }
        })
    },
    sureCommit(){
      if (this.form.discountPrice != '') {
        let data = {
          id: this.info.id,
          totalPrice: this.form.discountPrice
        }
        postRequest('/orderComputer/updateOrderInfo', data).then(res=>{
          console.log(res)
          this.$message({
            message: '修改成功',
            type: 'success'
          })
          this.handleClose()
        })
      } else {
        this.$message({
          message: '请输入折扣价',
          type: 'warning'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-dialog {
  padding: 25px;
}
.line-1 {
  background: #fff;
  margin-bottom: 25px;
  p {
    span {
      color: #5479FF;
    }
  }
}
.line-2 {
  background: #fff;
  .el-row {
    margin-bottom: 17px;
    padding-left: 10px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #676767;
  }
  .button-1 {
    display: flex;
    justify-content: flex-end;
  }
}
.line-3 {
  margin-top: 25px;
  background: #fff;
  .el-col {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #676767;
  }
}
.el-step__main {
  font-size: 12px !important;
}
p {
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 19px;
  font-weight: 400;
  font-family: PingFang SC;
}
.step-all {
  display: flex;
  align-items: center;
  .step-1 {
    :first-child {
      display: inline-block;
      width: 24px;
      height: 24px;
      background: #5067ff;
      text-align: center;
      line-height: 24px;
      border-radius: 50%;
      color: #fff;
    }
    :last-child {
      margin-left: 7px;
      font-size: 12px;
      color: #5479FF;
    }
  }
  .middleBox {
    width: 100px;
    border-top: 1px solid #999;
    margin: 0 5px;
  }
  .step-2 {
    :first-child {
      font-size: 18px;
      background: #5067ff;
      border-radius: 50%;
      color: #fff;
    }
    :last-child {
      margin-left: 5px;
      display: inline-block;
      height: 24px;
      line-height: 24px;
      font-size: 12px;
    }
  }
}
.p-span {
  color: #5479FF;
}
.totalMoney {
  color: #EF5252;
}
.step-all-2 {
  display: flex;
  align-items: center;
  .step-1 {
    :first-child {
      display: inline-block;
      width: 24px;
      height: 24px;
      background: #CCCCCC;
      text-align: center;
      line-height: 24px;
      border-radius: 50%;
      color: #fff;
    }
    :last-child {
      margin-left: 7px;
      font-size: 12px;
      color: #CCCCCC;
    }
  }
  .middleBox {
    width: 100px;
    border-top: 1px solid #CCCCCC;
    margin: 0 5px;
  }
  .step-2 {
    display: flex;
    :first-child {
      font-size: 18px;
      background: #CCCCCC;
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      color: #fff;
      text-align: center;
      line-height: 24px;
    }
    :last-child {
      margin-left: 5px;
      display: inline-block;
      height: 28px;
      line-height: 28px;
      font-size: 12px;
      color: #CCCCCC;
    }
  }
}
</style>